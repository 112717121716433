
import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux'
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { getTestVersion } from '../../redux/auth/action';
import { hideAlert } from '../../redux/alertActions';
import { showProgress, hideProgress } from "../../redux/progressActions";
import Box from '@material-ui/core/Box';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import InfoIcon from '@material-ui/icons/Info';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  ulStyle: {
    justifyContent: 'center'
  },
  avatar: {
    backgroundColor: '#0077c8',
    color: 'white'
  },
  cardButton: {
    display: 'block',
    textAlign: 'initial'
  },
  gridItem: {
    margin: '0 0 15px 0'
  },
  card: {
    minHeight: '100%'
  },
  cardContentPadding: {
    padding: '10px 16px'
  },
  cardTitle: {
    margin: '0',
    padding: '0',
    fontSize: '1.125rem',
    lineHeight: '1.33333333em',
    color: '#505050',
    fontFamily: 'Roboto Slab Bold,sans-serif'
  },
  cardHeader: {
    // minHeight: "100px"
  },
  cardAction: {
    // position: "absolute",
    // bottom: 0,
    // right: "25px"
    flexDirection: 'row-reverse'
  },
  noPadding: {
    padding: 0
  },


}));

const Tests = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [testVersions, setTestVersion] = useState([]);
  const cardTitleStyle = {
    fontSize:'1.125rem',
    margin:'0',
    color:'#505050',
    lineHeight: '1.33333333em',
    padding:'0',
    textDecoration:'none'
  };

  const testVersionElements = (testVersions && testVersions.length) ? (testVersions.map((test,i) => (
    <div role="listitem" style={{marginBottom:'10px'}} key={i}>
      <Link style={{textDecoration:'none'}} to={"/home/test/"+test.categoryId}>
        <div class="cb-card cb-card-title-link">
          <div style={{display:'flex',alignItems:'flex-start'}} class='cb-card-row'>
          <span id="icon" class="cb-icon cb-icon-circular cb-compose cb-purple1-color" aria-hidden="true"></span>
          <div id="content" class="cb-card-content">
            <h2 style={{cardTitleStyle}} class="cb-card-title titleHover">{test.categoryName}</h2>
              <span  className="cb-card-desc">
                {test.categoryDescription}
              </span>           
          </div>

          </div>

          <div id="arrow" class="cb-card-action">
            <Box class="cb-icon" style={{ color: "#0077c8" }}>
              <ArrowForwardIcon />
            </Box>
          </div>
        </div>
      </Link>
      </div>
  ))) : (<div className='listitem'>
          <p style={{marginLeft:'40%'}} className='text-center'>No Tests to Display</p>       
          </div>);
  
  useEffect(() => {
    dispatch(hideAlert());
    dispatch(showProgress());
    dispatch(getTestVersion()).then((res) => {
      if(res && res.testVersion) {
        setTestVersion(res.testVersion)
      } else {
        setTestVersion([]);
      }
      
      dispatch(hideProgress());
    })
  }, []);

  return (
    <Grid container direction='row' justifyContent='center' alignItems='stretch'>
        <Grid item xs={12} className='titleBar' align='center'>
          <Typography variant='h5' component='h1' color='primary'>
            Choose a Practice Test
        </Typography>
        </Grid>
        <Grid item className='col-xs-12 col-sm-7 cb-menu-lis cb-menu-list-glyph cb-menu-list-vertical  cb-menu-list-vertical cb-menu-list-separated
				cb-menu-list-accordion'>
          <div role="list" className={classes.ulStyle}>{testVersionElements}</div>
        </Grid>
      </Grid>
      );
    };
    export default Tests;
