
import React, { useEffect, useState, useRef } from 'react'
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import Avatar from '@material-ui/core/Avatar'
import Grid from '@material-ui/core/Grid'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import InfoRounded from "@material-ui/icons/InfoRounded";
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Paper from '@material-ui/core/Paper'
import { signUp } from '../../redux/auth/action'
import { useTranslation } from 'react-i18next';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { processNewUsrLink } from '../../redux/auth/action'
import queryString from 'query-string'
import { showAlert, hideAlert } from '../../redux/alertActions'
import { PasswordValidationRegex } from '../../shared/Validation';
import { showProgress, hideProgress } from "../../redux/progressActions";
import Progress from "../../Progress"
import ReactGA from 'react-ga';
import ErrorPage from '.././ErrorPage'
const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  leftpaper: {
    marginTop: theme.spacing(0),
    textAlign: 'left',
    padding: '10px',
    height: '100%'
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(0),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: '#fedb00',
    color: '#1e1e1e'
  },
  container: {
    marginBottom: '20px',
  },
  card: {
    maxWidth: 345,
  },
  passwordPolicy: {
    marginTop: '10%',
  },
  infoAvatar: {
    backgroundColor: theme.palette.primary.main,
    color: "white"
  }
}))


export default function SignUp(props) {
  const classes = useStyles()
  const divStyle = {
    marginTop: "2em",
    marginBottom: "4em"
  };
  const parsedUrl = queryString.parse(props.location.search);
  const [values, setValues] = useState({
    data: parsedUrl,
    isInviteUsr: false,
    isValidURL:true
  })
  const [isDisableSumbit, setIsDisableSumbit] = useState(false)
  const dispatch = useDispatch();
  const formRef = useRef();
  const store = useSelector(store => store)
  let isEmailExist = false;
  const handleSubmit = () => {
    dispatch(hideAlert())
    dispatch(showProgress())
    setIsDisableSumbit(true)
    dispatch(signUp(values)).then((res) => {
      if (res.data && res.data.result === "SUCCESS") {
    	  ReactGA.pageview("/registration/success");
    	  ReactGA.event({
			  category: "Login",
			  action: "SignUp Success",
			});
			window.dataLayer.push(
                  {'event': "custom_event",
                  'eventAction': "Signup Success",
              		'eventCategory': "Login",
              		'eventLabel': "Login"});

        dispatch(showAlert("Registration is complete, please login", "success"))
        setTimeout(() => {
        	dispatch(hideProgress())
          props.history.push("/login");
          setIsDisableSumbit(false)

        }, 5000);
      }
      else {
        setIsDisableSumbit(false)
        ReactGA.pageview("/registration/failed");
        ReactGA.event({
			  category: "Login",
			  action: "SignUp Failed",
			});
			window.dataLayer.push(
                        {'event': "custom_event",
                        'eventAction': "Signup Failed",
                    		'eventCategory': "Login",
                    		'eventLabel': "Login"});
        if (res.data.isEmailExists === 'Y') {
          isEmailExist = true;
          dispatch(showAlert("User already exists, try Login or Forgot Password", "error"))
          formRef.current.childs[2].validate();
        } else if (res && res.data.result === "FAILED"
        && res.data.email && res.data.email ==="Email Address is invalid"){
          dispatch(showAlert("Invalid email address", "error"))
        } else {
          dispatch(showAlert("Registration failed", "error"))
        }
        dispatch(hideProgress())
      }
    })
  }
  useEffect(() => {
    dispatch(hideAlert())
    if (store.login.isloggedIn) {
      props.history.push("/");
      return;
    }
    if (props.location.pathname && props.location.pathname === '/register'
      && props.location.search !== "" && props.location.search !== null) {
      const isInviteUsr = values.isInviteUsr
      console.log("IsIbvcite :", isInviteUsr);
      console.log("Values Process datra :", values);
      dispatch(processNewUsrLink({ ...values, isInviteUsr: true })).then((res) => {
        if (res && res.result === 'SUCCESS') {
        	ReactGA.pageview("/registration/newuser");
        	ReactGA.event({
  			  category: "Login",
  			  action: "Registration Success",
  			});
  			window.dataLayer.push(
                          {'event': "custom_event",
                          'eventAction': "Registration Success",
                      		'eventCategory': "Login",
                      		'eventLabel': "Login"});
          setValues({ ...values, isInviteUsr: true,isValidURL : res.isValidURL ,email: res.email, roleId: res.roleId })
        }else if(res && res.result === "FAILED" && (res.EXPIRED_LINK || res.isValidURL === false)) {
        	ReactGA.event({
            category: "Login",
            action: "Registration Failed",
          });
          window.dataLayer.push(
                            {'event': "custom_event",
                            'eventAction': "Registration Failed",
                        		'eventCategory': "Login",
                        		'eventLabel': "Login"});
          dispatch(showAlert(res.EXPIRED_LINK, "error"));
          setValues({ ...values, isValidURL : false});
        }
      })
    }
  }, []);

  useEffect(() => {
    dispatch(hideAlert())
    // custom rule for password matching
    ValidatorForm.addValidationRule('isPasswordMatch', value => {
      if (value && (value !== "") && (value !== values.password)) {
        return false;
      }
      return true;
    });

    // custom rule for password validation
    ValidatorForm.addValidationRule('matchPasswordCriteria', PasswordValidationRegex);

    // custom rule for already existing email
    ValidatorForm.addValidationRule('alreadyExists', () => {
      if (isEmailExist) {
        return false;
      }
      return true;
    });
  }, [values]);

  const handleInputChange = e => {

    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }
  const { t } = useTranslation();
  return (
    <div>
{ values && values.isValidURL ? ( <Container maxWidth="md" style={divStyle}>
        <div className=" ">
          <div className="row">
            <Paper className={`paper-box-container`} >
            <div className="row col-xs-12 col-sm-12" style={{height:'10px'}}>
            <Progress />
            </div>
              <div className="hDivider" ></div>
              <Grid container>
                <Grid item xs={12}>

                  <Container component="main" maxWidth="lg">
                    <div className={classes.paper}>

                      <Grid container>
                        <Grid item xs={12} sm={6}>
                          <Paper className={classes.leftpaper} >
                          <Avatar
                              className={`${classes.infoAvatar} margin-center`}
                            >
                              <InfoRounded />
                            </Avatar>
                            <Typography variant="h5" component="h2" style={{ textAlign: 'center' }} color="primary">
                              Information
                              </Typography><br />
                            <Typography component="p">
                              {t('signup.info')}
                            </Typography>
                            <Typography component="p" color="secondary">
                              {t('signup.pleaseCheckSpam')}
                            </Typography>
                            <Typography component="div" className={classes.passwordPolicy}>
                              <Typography component="p">
                                {t('resetPassword.passwordLength')}
                              </Typography>
                              <Typography component="div" color="secondary">
                                <ul className="passCriteriaUl" >
                                  <li>{t('resetPassword.lowercase')}</li>
                                  <li>{t('resetPassword.numeral')}</li>
                                  <li>{t('resetPassword.uppercase')}</li>
                                  <li>{t('resetPassword.specialCharacter')}</li>
                                </ul>
                              </Typography>
                            </Typography>

                          </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className={classes.paper}>
                            <Avatar className={`${classes.avatar} margin-center`}>
                              <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h2" variant="h5" align="center">
                              Sign up
                            </Typography><br />
                            <ValidatorForm
                              className={classes.form}
                              ref={formRef}
                              onSubmit={handleSubmit}
                              noValidate
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <TextValidator
                                    autoComplete="given-name"
                                    name="firstName"
                                    variant="outlined"
                                    fullWidth
                                    id="firstName"
                                    label="First Name"
                                    required
                                    autoFocus
                                    validators={['required']}
                                    errorMessages={['First Name is required.']}
                                    inputProps={{ maxLength: 100 }}
                                    onChange={handleInputChange}
                                    value={(values && values.firstName) || ''}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <TextValidator
                                    variant="outlined"
                                    fullWidth
                                    id="lastName"
                                    label="Last Name"
                                    name="lastName"
                                    required
                                    autoComplete="family-name"
                                    validators={['required']}
                                    errorMessages={['Last Name is required.']}
                                    inputProps={{ maxLength: 100 }}
                                    value={(values && values.lastName) || ''}
                                    onChange={handleInputChange}
                                  />
                                </Grid>
                                {!values.isInviteUsr && <Grid item xs={12}>
                                  <TextValidator
                                    variant="outlined"
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    type="email"
                                    required
                                    validators={['required', 'isEmail', 'alreadyExists']}
                                    errorMessages={['Email Address is required.', 'Invalid Email Address.', 'User already exists, try Login or Forgot Password.']}
                                    inputProps={{ maxLength: 250 }}
                                    value={(values && values.email) || ''}
                                    onChange={handleInputChange}
                                  />
                                </Grid>
                                }
                                <Grid item xs={12}>
                                  <TextValidator
                                    variant="outlined"
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    required
                                    autoComplete="new-password"
                                    validators={['required', 'matchPasswordCriteria']}
                                    errorMessages={['Password is required.', 'Password must follow the criteria.']}
                                    inputProps={{ maxLength: 50 }}
                                    value={(values && values.password) || ''}
                                    onChange={handleInputChange}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextValidator
                                    variant="outlined"
                                    fullWidth
                                    name="confirmpassword"
                                    label="Confirm Password"
                                    type="password"
                                    id="confirmpassword"
                                    required
                                    autoComplete="new-password"
                                    inputProps={{ maxLength: 50 }}
                                    validators={['required', 'isPasswordMatch']}
                                    errorMessages={['Confirm Password is required.', 'Passwords typed do not match. Please re-enter your password.']}
                                    value={(values && values.confirmpassword) || ''}
                                    onChange={handleInputChange}
                                  />
                                </Grid>
                              </Grid>
                              <button type="submit" disabled={isDisableSumbit} style={{marginTop:'24px'}} className="cb-btn  cb-btn-sm cb-btn-yellow full-width submitButton">Sign Up</button>
                              <br />
                              <br />
                              <Grid container justify="flex-end">
                                <Grid item>
                                  <Link style={{color:'#0077c8', textDecoration:'none'}} to="/" variant="body2">
                                    {t('signup.alreadyhaveaccount')}
                                  </Link>
                                </Grid>
                              </Grid>
                            </ValidatorForm>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </Container>
                </Grid>
              </Grid>
            </Paper>
          </div>
        </div>
      </Container>) : (<div>
          <ErrorPage isValidURL = {values.isValidURL}/>
      </div>)

}


    </div>
  )
}
