import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(theme => ({
    text: {
        paddingTop: '70px',
        textAlign: 'center'
    },
    grow: {
        flexGrow: 1,
    },
}))
const TitleBar = () => {
    const classes = useStyles()
    return (
        <div className="cb-margin-bottom-10">
        <div className="cb-band cb-band-hero cb-purple1-bg cb-band-hero-article " style={{height:"240px"}}>
            <div className="container">
                <div className="row align-items-center cb-band-hero-height-auto">
                    <div className="col-xs-12 col-sm-8 offset-sm-2 col-xl-6 offset-xl-3">
                        <div className='HomePagetitle'>
                        <div className="cb-band-hero-content cb-white-color ">
                            <h1 className="cb-band-hero-title" style={{fontSize:"3rem",padding:"2.5rem"}}>
                                Practice for ACCUPLACER
                            </h1>
                            <p className="cb-band-hero-desc ">
                            </p>
                        </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    )
}
export default TitleBar