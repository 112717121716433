import React, { useEffect,useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { FindInPage as PageView } from "@material-ui/icons";
import { Tooltip, Fab, Grid, CardContent, Card } from '@material-ui/core';
import { hideAlert, showAlert } from '../../redux/alertActions'
import { useDispatch } from 'react-redux';
import { startTest } from '../../redux/auth/action'
import { connect } from "react-redux";
import { getHistory, printHistory } from "../../redux/testHistoryAction";
import PrintIcon from '@material-ui/icons/Print'
import Button from '@material-ui/core/Button'
import MaterialTable from 'material-table';
import { showProgress, hideProgress } from "../../redux/progressActions";
import { getPAACertificateLink } from "../../redux/preAssessmentActions";

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  viewBtn: {
    backgroundColor: "#ffffff",
    color: "#000000",
    marginRight: "4px"
  },
  titleBar: {
    borderBottom: '4px solid #0077c8',
    marginBottom: '15px'
  },
  print: {
    position: "absolute",
    bottom: "30%",
    right: "2%"
  },
  printIcon: {
    marginRight: '5px'
  },
  button: {
    margin: theme.spacing(1),
    float: 'right',
	backgroundColor: "#ffffff",
    color: "#000000"
  }
}))
const TestHistory = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch();
  const dataTableRef = useRef();
  const [printDisable,setPrintDisable]= React.useState(false);
  
  useEffect(() => {
    dispatch(hideAlert());
    //accessibility fixes
    document.querySelector("[placeholder='Search by Test']").setAttribute("aria-label", "search by test");
    document.getElementsByClassName('MuiTableRow-head')[0].setAttribute("tabindex", "0");
    if (document.getElementsByClassName('MuiTableCell-head').length > 0) {
      for (let index = 0; index < document.getElementsByClassName('MuiTableCell-head').length; index++) {
        document.getElementsByClassName('MuiTableCell-head')[index].setAttribute('scope', 'col');
        document.getElementsByClassName('MuiTableCell-head')[index].addEventListener('click', thclick);
        document.getElementsByClassName('MuiTableCell-head')[index].addEventListener("keyup", event => {
          if ((event.which === 32 || event.keyCode === 32) || (event.which === 13 || event.keycode === 13)) {
            event.currentTarget.click();
          }
        });
      }
      let rowHeaderElement = document.querySelectorAll("tbody.MuiTableBody-root > tr.MuiTableRow-root > td.MuiTableCell-root:first-child");
      if(rowHeaderElement.length) {
        for (let i = 0; i < rowHeaderElement.length; i++) {
          rowHeaderElement[i].setAttribute('role', 'rowheader');
        }
      }
    }
  }, [])

  const thclick = (event) => {
    if (event.currentTarget.firstChild.firstElementChild.classList.contains('MuiTableSortLabel-iconDirectionAsc')) {
      if (event.currentTarget.getAttribute('aria-sort') === 'ascending') {
        event.currentTarget.setAttribute('aria-sort', 'descending')
      } else if (event.currentTarget.getAttribute('aria-sort') === 'descending') {
        event.currentTarget.setAttribute('aria-sort', 'ascending')
      } else {
        event.currentTarget.setAttribute('aria-sort', 'ascending')
      }
    } else {
      if (event.currentTarget.getAttribute('aria-sort') === 'ascending') {
        event.currentTarget.setAttribute('aria-sort', 'descending')
      } else if (event.currentTarget.getAttribute('aria-sort') === 'descending') {
        event.currentTarget.setAttribute('aria-sort', 'ascending')
      } else {
        event.currentTarget.setAttribute('aria-sort', 'ascending')
      }
    }
  }
   const printTestHistory = () => {
	   dispatch(hideAlert());
	   dispatch(showProgress())
    dispatch(printHistory()).then(res => {
     if(res && res.status === "SUCCESS"){
    	 window.open(res.FILE_URL, "_blank")
    	 dispatch(hideProgress())
     } else {
    	 dispatch(showAlert("Test print failed or no test found", "error"));
     }
     dispatch(hideProgress())
    });

  }
        
  const viewCompletedTestHistory = (currentTestDetailId, currentTestSessionDetailId,currentTestName,currentTestAlias, paaId) => {
    dispatch(showProgress());
    if(paaId) {
        dispatch(getPAACertificateLink(paaId)).then(link => {
            dispatch(hideProgress());
            window.open(link, "_blank");
        });
    } else {
        let tdDomainUrl = props.urlData.tddomainUrl;
    var path = 'administer';
    var jsonData = {
      administerTestVO: {
        testDetailId: currentTestDetailId,
        testSessDetailId: currentTestSessionDetailId,
        isComplete: true,
        isOver: true,
        isPracticeTest: true,
        isPtResultsCompleted: true,
        testName:currentTestName,
        testAlias:currentTestAlias
      },
      isKioskApp: false,
      kioskData: {},
      path: path,
      studentIdPk: '1',
      ethnicity: "Asian, Asian American or Pacific Islander",
      gender: "Male",
      firstName: 'tmp',
      lastName: 'tmp',
      studentId: 1,
      isPracticeTestHistoryView: true
    };

    dispatch(startTest(jsonData)).then((res) => {
      let tdURL;
      if (tdDomainUrl && tdDomainUrl !== null && tdDomainUrl !== '') {
        tdURL = tdDomainUrl + "#/?d=" + res;
      } else {
        tdURL = "http://test.local-accuplacer.com:9001/#/?d=" + res;
      }

      let screen = window.screen;
      let wwidth = screen.width, wheight = screen.height, LeftPosition = 0, TopPosition = 0;
      var varWin = window.open(tdURL, 'atWindow', 'menubar=no,height=' + wheight + ', width=' + wwidth + ',top=' + TopPosition + ',left=' + LeftPosition +
        ',scrollbars=yes, resizable=yes, toolbar=no, menubar=no, status=no,location=no');
      varWin.moveTo(0, 0)
      varWin.resizeTo(screen.width, screen.height);
      varWin.focus();
    });
    }
  }

  return (
    <React.Fragment>
      <Grid item xs={12} className='titleBar col-xs-12' style={{height:'112px'}} align='center'>
        <Typography variant='h5' component='h2' color='primary'>
          History
          </Typography>
          <div style ={{maxHeight: 200, overflow: 'auto'}}>
            {props.urlData.userName}       
          </div>
          <Button variant="contained" className={classes.button} disabled ={printDisable} onClick={() => {printTestHistory()}}><PrintIcon className={classes.printIcon}/> Print</Button>
         
      </Grid>
      <Grid container item xs={12} >
        <div className="col-sm-12" >
          <Card className={classes.card}>
            <CardContent>
            <MaterialTable
            title={<Typography variant='h6' component='h3'>
            Test History
            </Typography>}
            	tableRef={dataTableRef}
            data={query => 
            new Promise((resolve, reject) => {
            	dispatch(getHistory({query })).then(result => {
                if(result.totalCount===0)
                  setPrintDisable(true);
                else
                  setPrintDisable(false);
            		resolve({
                        data: result.data,
                        page: result.page,
                        totalCount: result.totalCount,
                      })  
            	})
          })
            
            }
            
            options={{
            	actionsColumnIndex:6,
            	headerStyle:{fontWeight: 'bold'
            	},
            	actionsCellStyle: {
            		width:100,
            		paddingLeft:20
            	},
            	searchFieldStyle: {searchPlaceholder:'Search by Test'},
              search: true,
              sorting: true,
              grouping: false,
              draggable: false,
              pageSize: 10,
              pageSizeOptions:[10,25,50,100,250],
              emptyRowsWhenPaging:false,
              thirdSortClick:false
            }}
            localization={{
            	header: {
                    actions: 'Action'
                },
                toolbar: {
                	searchTooltip:'Search by Test',
                	searchPlaceholder:'Search by Test'
                },
                pagination : {
                  firstAriaLabel: 'First Page',
                  previousAriaLabel: 'Previous Page',
                  lastAriaLabel: 'Last Page',
                  nextAriaLabel: 'Next Page'
                }
            }}
            columns={[
            	 
            	{ title: 'Test Name', field: 'testName',
            		
            		render: rowData => rowData.testName,
            		},
                { title: 'Score', field: 'testScore' ,
                		render: rowData =>  ((rowData.paaId && rowData.paaId > 0) ? 'N/A' : rowData.testScore + '%'),
                		},
        		{ title: 'Completed On', field: 'testCompletionDate' ,
            		render: rowData => new Date(rowData.testCompletionDate).toLocaleDateString(),
            		}
              ]}
            components={{
                Action: props => (
                		<Tooltip title="View Result" aria-label="view">
                        <Fab
                          className={classes.viewBtn}
                          size="small"
                          onClick={() => viewCompletedTestHistory(props.data.testDetailId, props.data.testSessionDetailId,props.data.testName,props.data.testAlias, props.data.paaId)}
                        >
                          <PageView  />
                        </Fab>
                      </Tooltip> 
                )
                
              }}
            actions={[
                {
                  icon: 'view',
                  tooltip: 'View',
                  onClick: (event, row) => {
                    // Do save operation
                	  viewCompletedTestHistory(row.testDetailId, row.testSessionDetailId,row.testName,props.data.testAlias, props.data.paaId)
                  }
                }
                
                
              ]}
          />
            
            
            </CardContent>
          </Card>
        </div>
      </Grid>
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  urlData: state.login.data
});
export default connect(mapStateToProps)(TestHistory)