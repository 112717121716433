import React, { Component, forwardRef } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import InviteUser from "./InviteUser";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import {  Grid,  Tooltip,  Fab,   Card,  CardHeader,  CardContent} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import DeleteIcon from "@material-ui/icons/Delete";
import PersonIcon from "@material-ui/icons/People";
import GetAppIcon from "@material-ui/icons/GetApp";
import Search from '@material-ui/icons/Search';
import { getUsers, exportUsers, deleteUser } from "../../redux/userActions";
import { showAlert, hideAlert } from "../../redux/alertActions";
import { showProgress, hideProgress } from "../../redux/progressActions";
import MaterialTable from 'material-table';
import { showModal, hideModal } from "../../redux/modalAction";
const styles = theme => ({
  button: {
    margin: theme.spacing(0, 0, 2),
    backgroundColor: "#fedb00",
    color: "#1e1e1e"
  },
  secondaryButton: {
    backgroundColor: "#e0e0e0",
    color: "#1e1e1e"
  },
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  headerSpan: {
    float: "left",
    margin: "1%",
    padding: "4px"
  },
  headerTitle: {
    textAlign: "left",
    margin: "1%"
  },
  gridMargin: {
    marginTop: "3%"
  },
  deleteBtn: {
    backgroundColor: "#fedb00",
    color: "#1e1e1e"
  },
  noRecordsDispDiv: {
    height: "100px",
    marginTop: "10px",
    textAlign: "center"
  },
  invite: {
    position: "absolute",
    bottom: "3px",
    right: "15px",
  },
  downloadBtn: {
    position: "absolute",
    right: "2%",
    bottom: "1%"
  },
  viewBtn: {
    backgroundColor: "#ffffff",
    color: "#000000",
    marginRight: "4px"
  },
  requiredMsg: {
    position: "absolute",
    left: "2%"
  },
  card: {
    textAlign: "left",
    marginBottom: "20px"
  },
  cardHeader: {
    backgroundColor: "dodgerblue",
    color: "white"
  },
  cardAction: {
    float: "right"
  }
});

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchObj: {
        firstName: "",
        lastName: "",
        emailAddress: "",
        userRoleId: "",
        submitted: false
      },
      rows: [],
      totalRows: 0,
      disableDownloadBtn:false
    };
    this.dataTableRef = React.createRef();
  }
  handleChange = e => {
      let { searchObj } = this.state;
      const { name, value } = e.target;
      searchObj[name] = value;
      this.setState({ searchObj });
    };
    handleReset = () => {
      this.setState({
        searchObj: {
          firstName: "",
          lastName: "",
          emailAddress: "",
          userRoleId: "",
          submitted: false
        }
      });
    };
  
  refreshList = () => {
	  this.props.hideAlert();
	  if(this.dataTableRef.current) {
		  this.dataTableRef.current.onQueryChange({filters:[]});
	  }
  }

    handleSearch = () => {
  	  console.log(this.dataTableRef);
  	  const { totalRows, searchObj } = this.state;
  	    const payload = {
  	      totalRows,
  	      searchObj,
  	      isDownloadUsers: false
  	    };
  	    this.props.getUsers(payload).then(res => {
  	     if(res){
  	      this.props.hideProgress();
  	      console.log(this.dataTableRef.current);
  	      //this.dataTableRef.current.onQueryChange()
  	     } else {
  	    	 this.props.showAlert("Users export failed or no users found", "error");
  	     }
  	    });
    };
	  
  getUserList = tableOptions => {
	  this.props.hideAlert();
this.props.showProgress();
    let { searchObj } = this.state;
    const payload = { ...tableOptions, searchObj};
    this.props.getUsers(payload).then(response => {
      console.log(this.state);
      this.setState({
        rows: response.usersList,
        totalRows: response.totalRows
      });
      this.props.hideProgress();
    });
  };
  handleDownloadUsers = () => {
	  this.props.hideAlert();
	  
	  if(this.dataTableRef.current.state.data.length > 0) {
		    this.props.showProgress();
		    console.log("props :" ,this.props);
		    console.log("props :" ,this.dataTableRef.current.state.query);
		    const { totalRows, searchObj } = this.state;
		    const payload = {
		      totalRows,
		      searchObj,
		      isDownloadUsers:true,
		      query : this.dataTableRef.current.state.query
		    };
		    this.props.exportUsers(payload).then(res => {
		     if(res && res.status === "SUCCESS"){
		      this.props.hideProgress();
		      const link = document.createElement("a");
		     link.download = res.FILE_NAME;
		     link.href = res.FILE_URL;
		     document.body.appendChild(link);
		     link.click();
		     document.body.removeChild(link);
		     link.remove();
		     } else {
		    	 this.props.showAlert("Users export failed or no users found", "error");
		     }
		     this.props.hideProgress();
		    })

	  } else {
		  this.props.showAlert("No user found for download", "error");
	  }
  }

  performDeleteUser = (...userEmail) => {
	  this.props.hideAlert()
	  console.log(userEmail);
	  this.props.showModal({
		  title: "Delete User",
          message: "Are you sure you want to delete the user?",
          confirmAction: this.handleDeleteUser,
          confirmActionParams: userEmail,
          closeModal: this.props.hideModal,
          dialogAttr: {
        	  fullWidth:true,
        	  maxWidth: "sm"
          }
	  });
  }
  
  handleDeleteUser = userEmail => {
	  console.log(userEmail);
    let userData = {
      emailAddress: userEmail
    }
    this.props.showProgress();
    console.log("userEmail", userData);
    this.props.deleteUser(userData).then(res => {
      if (res === "success") {
        this.getUserList({ ...this.dataTableRef.current.state });
        this.props.showAlert("User deleted sucessfully", "success");
      }
      this.props.hideProgress();
      this.refreshList();
    });
    this.props.hideModal();
  };
  componentDidMount() {
    this.props.hideAlert();
  }
  componentWillUnmount() {
    this.props.hideAlert();
  }

  render() {
    const { classes, t } = this.props;
    return (
      <React.Fragment>
        {/* <div className="row hidden-sm hidden-md hidden-lg full-width">
          <div className="pull-right">
            <InviteUser classes={classes} />
          </div>
        </div> */}

        <Grid item xs={12} className="titleBar col-xs-12" align="center">
          <Typography variant="h5" component="h2" color="primary">
            {t("users.title")}
          </Typography>
          <div className={`${classes.invite} hidden-xs`}>
            <InviteUser classes={classes} />
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className="col-sm-12">
            <Card className={classes.card}>
              <CardHeader
                avatar={<PersonIcon />}
                title={t("users.searchResults")}
                className="section-header"
                action={
                  <div>
                    <Tooltip title="Download" aria-label="Download">
                      <Fab
                        className={classes.viewBtn}
                        size="small"
                        onClick={e => this.handleDownloadUsers()}
                      >
                        <GetAppIcon />
                      </Fab>
                    </Tooltip>

                    <Tooltip title="Refresh" aria-label="refresh">
                      <Fab
                        className={classes.viewBtn}
                        size="small"
                        onClick={() => {
                          this.refreshList();
                        }}
                      >
                        <RefreshIcon />
                      </Fab>
                    </Tooltip>
                  </div>
                }
              />
              <CardContent id="userContent">
                <MaterialTable
                  title=""
                  tableRef={this.dataTableRef}
                  data={query =>
                    new Promise((resolve, reject) => {
                      this.props.getUsers({ query }).then(result => {
                        resolve({
                          data: result.data,
                          page: result.page,
                          totalCount: result.totalCount
                        });
                      })
                    })
                  }
                  icons={{
                    Filter: forwardRef((props, ref) => (
                      <Search {...props} ref={ref} aria-label="Filter" />
                    ))
                  }}
                  options={{
                    actionsColumnIndex: 6,
                    headerStyle: { fontWeight: "bold", whiteSpace: "nowrap" },
                    actionsCellStyle: {
                      width: 100,
                      paddingLeft: 20
                    },
                    grouping: false,
                    draggable: false,
                    filtering: true,
                    searchFieldStyle: { searchPlaceholder: "Search by Name" },
                    search: false,
                    sorting: true,
                    pageSize: 10,
                    pageSizeOptions: [10, 25, 50, 100, 250],
                    emptyRowsWhenPaging: false
                  }}
                  localization={{
                    header: {
                      actions: "Action"
                    },
                    toolbar: {
                      searchTooltip: "Search by Name",
                      searchPlaceholder: "Search by Name"
                    },
                    pagination: {
                      firstAriaLabel: "First Page",
                      previousAriaLabel: "Previous Page",
                      lastAriaLabel: "Last Page",
                      nextAriaLabel: "Next Page"
                    },
                    body: {
                      filterRow: {
                        filterTooltip: "Filter",
                        firstName: "Filter by First Name"
                      }
                    }
                  }}
                  columns={[
                    {
                      title: "First Name",
                      field: "firstName",
                      cellStyle: { maxWidth: "100px", wordBreak: "break-all" },
                      render: rowData => rowData.firstName
                    },
                    {
                      title: "Last Name",
                      field: "lastName",
                      cellStyle: { maxWidth: "100px", wordBreak: "break-all" },
                      render: rowData => rowData.lastName
                    },
                    {
                      title: "Email Address",
                      field: "emailAddress",
                      cellStyle: { maxWidth: "150px", wordBreak: "break-all" },
                      render: rowData => rowData.emailAddress
                    },
                    {
                      title: "User Role",
                      field: "roleName",
                      render: rowData => rowData.roleMaster.roleName,
                      lookup: {
                        3: t("inviteUser.userRoleMenuItems.contentAdmin"),
                        4: t("inviteUser.userRoleMenuItems.reviewer"),
                        5: t("inviteUser.userRoleMenuItems.publisher"),
                        2: t("inviteUser.userRoleMenuItems.student")
                      },
                      filterCellStyle: {
                        paddingTop: "0px",
                        marginTop: "0px"
                      }
                    },
                    {
                      title: "User Registered Date",
                      field: "createdOn",
                      filtering: false,
                      render: rowData =>
                        new Date(rowData.createdOn).toLocaleDateString()
                    },
                    {
                      title: "User Last Login",
                      field: "lastLogin",
                      filtering: false,
                      render: rowData => (
                        <span>
                          {rowData.lastLogin &&
                            new Date(
                              rowData.lastLogin
                            ).toLocaleDateString()}{" "}
                          {rowData.lastLogin &&
                            new Date(rowData.lastLogin).toLocaleTimeString()}
                        </span>
                      )
                    }
                  ]}
                  components={{
                    Action: props => (
                      <div>
                        <Tooltip title="Delete" aria-label="Delete">
                          <Fab
                            className={classes.viewBtn}
                            size="small"
                            onClick={e =>
                              this.performDeleteUser(props.data.emailAddress)
                            }
                          >
                            <DeleteIcon />
                          </Fab>
                        </Tooltip>
                      </div>
                    )
                  }}
                  actions={[
                    {
                      icon: "delete",
                      tooltip: "Delete",
                      onClick: (event, row) => {
                        // Do save operation
                        this.performDeleteUser(row.emailAddress);
                      }
                    }
                  ]}
                />
              </CardContent>
            </Card>
          </div>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getUsers: srchObj => dispatch(getUsers(srchObj)),
  exportUsers: srcObj => dispatch(exportUsers(srcObj)),
  deleteUser: srcObj => dispatch(deleteUser(srcObj)),
  showAlert: (msg, style, heading) => dispatch(showAlert(msg, style, heading)),
  hideAlert: () => dispatch(hideAlert()),
  showProgress: () => dispatch(showProgress()),
  hideProgress: () => dispatch(hideProgress()),
  showModal: (param) => dispatch(showModal(param,"confirm")),
  hideModal: () => dispatch(hideModal())
});
const mapStateToProps = state => ({
  ...state
});
export default compose(
  withTranslation(),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withStyles(styles)
)(Users);