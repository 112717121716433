import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import { getTests } from "../../redux/userActions";
import { startTest } from "../../redux/auth/action";
import { connect } from "react-redux";
import { hideAlert } from "../../redux/alertActions";
import Badge from "@material-ui/core/Badge";
import { showProgress, hideProgress } from "../../redux/progressActions";
import ReactGA from "react-ga";
import { showModal, hideModal } from "../../redux/modalAction";
import { storeWindow } from "../../redux/windowActions";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  margin: {
    margin: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(2),
    boxShadow: "None",
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  ulStyle: {
    justifyContent: "center",
    listStyle:"none"
  },
  button: {
    margin: theme.spacing(1)
  }
}));

const Test = props => {
  const classes = useStyles();
  const [horizontal] = React.useState("left");
  const [vertical] = React.useState("top");
  const dispatch = useDispatch();
  const [itemsData, setItems] = useState([]);
  const [userId, setUserId] = useState(0);
  const [noitem, setNoitem] = useState("");

  useEffect(() => {
    dispatch(hideAlert());
    // console.log(props);
    let testCategoryData = {
      categoryId: props.match.params.id
    };
    dispatch(showProgress());
    dispatch(getTests(testCategoryData)).then(res => {
      setItems(res.testData);
      if (res.testData.length === 0) {
        setNoitem("No test available");
      }
      setUserId(res.userId);
      dispatch(hideProgress());
    });
  }, []);

  const dialogDesc = (
    <React.Fragment>
      Are you sure you want to continue? If yes, the in-progress test session will be closed and a new test session will begin.
    </React.Fragment>
  );
  const dialogTitle = (
    <React.Fragment>A test is already in-progress!</React.Fragment>
  );

  const dialogOnSubmit = (userData, dataUserId, isSampleTest, ptTestMode) => {
    // close the existing window and starting the new test.
    if (props.windowObject && props.windowObject.w) {
      props.windowObject.w.close();
    }
    handleTestOnClick(userData, dataUserId, isSampleTest, ptTestMode);
    dispatch(hideModal());
  };

  const dialogOnClose = () => {
    // cancel the request and put focus back to the already in-progress test.
    if (props.windowObject && props.windowObject.w) {
      props.windowObject.w.focus();
    }
    dispatch(hideModal());
  };

  const dialogAttr = {
    disableBackdropClick: true,
    disableEscapeKeyDown: true
  };

  const openConfirmModal = (...callBackParams) => {
    dispatch(
      showModal(
        {
          title: dialogTitle,
          message: dialogDesc,
          confirmAction: dialogOnSubmit,
          confirmActionParams: callBackParams,
          closeModal: dialogOnClose,
          dialogAttr: dialogAttr
        },
        "confirm"
      )
    );
  };

  const confirmStartTest = (userData, dataUserId, isSampleTest, ptTestMode) => {
    if (props.windowObject && props.windowObject.w && !props.windowObject.w.closed) {
      openConfirmModal(userData, dataUserId, isSampleTest, ptTestMode);
    } else {
      handleTestOnClick(userData, dataUserId, isSampleTest, ptTestMode);
    }
  };

  const populateWindowObject = (userData, dataUserId, w) => {
    // console.log(props.windowObject);
    const newWindowObject = {};
    newWindowObject.testAlias = userData.testAlias;
    newWindowObject.userId = dataUserId;
    newWindowObject.w = w;
    dispatch(storeWindow(newWindowObject));
  };

  const handleTestOnClick = (
    userData,
    dataUserId,
    isSampleTest,
    ptTestMode
  ) => {
    let tdDomainUrl = props.urlData.tddomainUrl;
    let path;
    let pendingSessionId;
    let testCategoryData = {
       categoryId: props.match.params.id
    };
    dispatch(showProgress());
    dispatch(getTests(testCategoryData)).then(res => {
      setItems(res.testData);
      userData = res.testData.filter((data) => data.bpId === userData.bpId)[0]
      setUserId(res.userId);
      dispatch(hideProgress());

      if (userData) {
        if (isSampleTest && userData.pendingSAMPSessionId) {
          pendingSessionId = userData.pendingSAMPSessionId;
          path = 'resumePracticeTestSession';
        } else if (!isSampleTest && userData.pendingLAYGSessionId) {
          pendingSessionId = userData.pendingLAYGSessionId;
          path = 'resumePracticeTestSession';
        } else {
          path = "welcome";
        }
      } else {
        path = "welcome";
      }
      ReactGA.event({
        category: userData.branchingProfileName,
        action: "Test Administration",
        value: {
          userId: dataUserId,
          testAlias: userData.testAlias,
          bpId: userData.branchingProfileId,
          bpName: userData.branchingProfileName,
          path: path
        }
      });

      var jsonData = {
        testAlias: userData.parentTestAlias,
        categoryId: userData.categoryId,
        administerTest: true,
        isDemoSite: false,
        isKioskApp: false,
        kioskData: {},
        path: path,
        ethnicity: "N/A",
        gender: "N/A",
        firstName: userData.firstName,
        lastName: userData.lastName,
        isPracticeTest: true,
        isSamplePtTest: isSampleTest,
        ptTestMode: ptTestMode,
        pendingSessionId: pendingSessionId
      };

      dispatch(startTest(jsonData)).then(res => {
        // console.log(res);
        ReactGA.set({
          page: window.location.pathname + "/" + userData.branchingProfileName
        });
        ReactGA.pageview(
          window.location.pathname + "/" + userData.branchingProfileName
        );

        window.dataLayer.push(
            {'event': 'custom_event',
            'eventAction': window.location.pathname + "/" + userData.testName,
        		'eventCategory': 'Test Selection',
        		'eventLabel': !isSampleTest ? "Learn as you go" : "Practice Test"});

        let tdURL;
        if (tdDomainUrl && tdDomainUrl !== null && tdDomainUrl !== "") {
          tdURL = tdDomainUrl + "#/?d=" + res;
        } else {
          tdURL = "http://test.local-accuplacer.com:9001/#/?d=" + res;
        }

        let screen = window.screen;
        let wwidth = screen.width,
          wheight = screen.height,
          LeftPosition = 0,
          TopPosition = 0;
        var varWin = window.open(
          tdURL,
          "atWindow",
          "menubar=no,height=" +
          wheight +
          ", width=" +
          wwidth +
          ",top=" +
          TopPosition +
          ",left=" +
          LeftPosition +
          ",scrollbars=yes, resizable=yes, toolbar=no, menubar=no, status=no,location=no"
        );
        if (varWin) {
          varWin.moveTo(0, 0);
          varWin.resizeTo(screen.width, screen.height);
          varWin.focus();
          populateWindowObject(userData, dataUserId, varWin);
        }
      });
    });
  };

  return (

    <Grid container direction="row" justifyContent="center" alignItems="baseline">
      <Grid item xs={12} className='titleBar' align='center'>
        <Typography variant='h5' component='h1' color='primary'>
          Select a Practice Test to Begin
                </Typography>
      </Grid>
      <Grid item className='col-xs-12 col-sm-6 cb-item-list cb-item-list-rich'>
        <ul className={classes.ulStyle}>
          {itemsData.length === 0 && (<Typography variant="h5" component="h5">
            {noitem}
          </Typography>)}
          {itemsData.length > 0 && itemsData.map((row, index) => (
            <li className='cb-type-article noTitleBar' key={index}>
              <div className="cb-item-container">
              <div id="icon" className='cb-icon'>
                <span className='cb-icon cb-icon-circular cb-compose cb-purple1-color' aria-hidden='true'></span>
                <span className="cb-item-type" style={{display:'none'}}>{row.testName}</span>
              </div>
              <div id="text" className='cb-item-text' >
                <h2 style={{margin: '0',padding: '0',fontSize: '1.125rem',lineHeight: '1.33333333em',color: '#505050'}}>{row.testName}</h2>
                {/* <p className='cb-item-des'><strong>{row.testCategoryName}</strong></p> */}
                <p className='cb-item-des' style={{margin: '0',fontSize: '.875rem',lineHeight: '1.71428571em',color: '#505050'}}>{row.testDescription}</p>
              </div>
              </div> 
              <div className='cb-item-meta' ><Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                {row.pendingLAYGSessionId != null &&
                  <Badge
                    color="secondary"
                    badgeContent={"In Progress"}
                    anchorOrigin={{
                      horizontal,
                      vertical,
                    }}
                    overlap="circular"
                    className={classes.margin}
                  >
                    <Button variant="outlined" color="primary" className={classes.button} onClick={(event) => confirmStartTest(row, userId, false, 1)}
                      data-testalias={row.testAlias} aria-label={`learn as you go ${row.testName} test in progress`}>Learn As You Go</Button>
                  </Badge>
                }
                {row.pendingLAYGSessionId == null &&
                  <Button variant="outlined" color="primary" className={classes.button} onClick={(event) => confirmStartTest(row, userId, false, 1)}
                    data-testalias={row.testAlias} aria-label={`learn as you go ${row.testName} test`}>Learn As You Go</Button>}


                &nbsp;&nbsp;
                {row.pendingSAMPSessionId != null &&
                  <Badge
                    color="secondary"
                    badgeContent={"In Progress"}
                    anchorOrigin={{
                      horizontal,
                      vertical,
                    }}
                    overlap="circular"
                    className={classes.margin}
                  >
                    <Button variant="outlined" color="primary" className={classes.button} onClick={(event) => confirmStartTest(row, userId, true, 2)}
                      data-testalias={row.testAlias} aria-label={`practice test ${row.testName} test in progress`}>Practice Test</Button>
                  </Badge>
                }
                {row.pendingSAMPSessionId == null &&
                  <Button variant="outlined" color="primary" className={classes.button} onClick={(event) => confirmStartTest(row, userId, true, 2)}
                    data-testalias={row.testAlias} aria-label={`practice test ${row.testName} test`}>Practice Test</Button>}


              </Grid>
              </div>

            </li>
          ))}
        </ul>
      </Grid>


    </Grid>
  )
}

const mapStateToProps = state => ({
  urlData: state.login.data,
  windowObject : state.window.windowObj
});
export default connect(mapStateToProps)(Test)
