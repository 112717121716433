import React, { useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { FindInPage as PageView, Check } from "@material-ui/icons";
import { Card, CardContent, CardHeader, CardActions, Tooltip, Grid, Fab, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux'
import { startTest } from '../../redux/auth/action'
import { getCPData, getCPItems, approveOrRejectCp } from '../../redux/importActions';
import { connect } from "react-redux";
import { showAlert, hideAlert } from '../../redux/alertActions'
import ACL from '../ACL';
import { getTests } from '../../redux/userActions';
import { useTranslation } from "react-i18next";
import MaterialTable from 'material-table';
import RejectionDialog from './RejectionDialog'
import { showModal, hideModal } from "../../redux/modalAction";
import Link from '@material-ui/core/Link';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
    },
    tableWrapper: {
        overflowX: 'auto',
    },
    titleBar: {
        borderBottom: '4px solid #0077c8',
        marginBottom: '15px'
    },
    card: {
        textAlign: "left",
        marginBottom: "20px"
    },
    cardHeader: {
        backgroundColor: "dodgerblue",
        color: 'white'
    },
    cardAction: {
        float: "right"
    }, importBtn: {
        backgroundColor: "#fedb00",
        color: "#1e1e1e"
    },
    importHistoryBtn: {
        textAlign: 'left',
        padding: '10px',
        color: "white",
        backgroundColor: "dodgerblue",
        display: 'flex',
        alignItems: 'center'
    },
    filteDeleteBtn: {
        paddingTop: '13px',
        fontSize: '18px',
        fontWeight: 600,
    },
    viewBtn: {
        backgroundColor: "#ffffff",
        color: "#000000",
        marginRight: "4px"
    },
    buttonWhite: {
        backgroundColor: "#ffffff",
        color: "#1e1e1e",
    },
    button: {
        backgroundColor: "#fedb00",
        color: "#1e1e1e",
    },
    red: {
        backgroundColor: "#f9cdcd"
    },
    green: {
        backgroundColor: "#dff3df"
    },
    labelFont:{
        fontWeight:'700'
    }
}))

const ViewPracticeTest = (props) => {
    const classes = useStyles()
    const { t } = useTranslation();
    const [testDetail, setTestDetail] = React.useState({});
    const [cpData, setCPData] = React.useState({});
    const [cpAudit, setCPAudit] = React.useState([]);
    const [processLog, setProcessLog] = React.useState([]);
    const dataTableRef = useRef();
    const dispatch = useDispatch();
    const [itemsData, setItems] = React.useState([])
    const [userId, setUserId] = React.useState(0);
    const [cpStatus, setCpStatus] = React.useState(0);
    const [itemListCount, setTotalRowCount] = React.useState(0);
    const [cpId, setCpId]= React.useState(0);
    useEffect(() => {
        handleGetTestItems()
    }, [cpStatus])
    
    const handleGetTestItems = (tableOptions) => {
        dispatch(getCPData({ cpId: props.match.params.id })).then(res => {
            setCPAudit(res.cpUploadData.cpAudit);
            setCPData(res.cpUploadData.cpData);
            setCpStatus(res.cpUploadData.cpData.cpStatusDetail.statusId);
            if (res.cpUploadData.processLog) {
                setProcessLog(res.cpUploadData.processLog);
                console.log(res.cpUploadData.processLog);
            } 
            if (res.cpUploadData.testDetail) {
                setTestDetail(res.cpUploadData.testDetail);
            }
            let testCategoryData = {
                categoryId: res.cpUploadData.cpData.cpTestCategory.categoryId,
                testDetailId: res.cpUploadData.cpData.testDetailId,
                cpId:res.cpUploadData.cpData.id
            }
            dispatch(getTests(testCategoryData)).then((res) => {
                setItems(res.testData[0]);
                setUserId(res.userId);
                setCpId(testCategoryData.cpId);
            })

        });
    }
    const handlePracticeItemPreview = (currentItemId, currentItemTestDetailId, currentItemStrandDetailId, currentTestName) => {

        let tdDomainUrl = props.urlData.tddomainUrl;

        var jsonData = {
            flag: true,
            fromModule: "ITEM_BANK",
            isKioskApp: false,
            itemIds: currentItemId,
            kioskData: null,
            methodRequest: "studentItemPreview",
            testName: currentTestName,
            testTypeId: 15,
            administerTest: false,
            path: 'studentPreviewBankItems',
            practiceTest: true,
            isPracticeTest: true,
            testDetailId: currentItemTestDetailId,
            strandDetailId: currentItemStrandDetailId
        };

        dispatch(startTest(jsonData)).then((res) => {
            console.log(res);
            let tdURL;
            if (tdDomainUrl && tdDomainUrl !== null && tdDomainUrl !== '') {
                tdURL = tdDomainUrl + "#/?d=" + res;
            } else {
                tdURL = "http://test.local-accuplacer.com:9001/#/?d=" + res;
            }

            let screen = window.screen;
            let wwidth = screen.width, wheight = screen.height, LeftPosition = 0, TopPosition = 0;
            var varWin = window.open(tdURL, 'atWindow', 'menubar=no,height=' + wheight + ', width=' + wwidth + ',top=' + TopPosition + ',left=' + LeftPosition +
                ',scrollbars=yes, resizable=yes, toolbar=no, menubar=no, status=no,location=no');
            varWin.moveTo(0, 0)
            varWin.resizeTo(screen.width, screen.height);
            varWin.focus();
        })

    }
    function closeModel() {
  	  dispatch(hideModal());
    }
    const performApproveCP = (...cpId) => {
  	  dispatch(hideAlert());
  	  console.log(cpId);
  	  dispatch(showModal({
  		  title: "Approve Content Package",
            message: "Are you sure you want to approve the Content Package?",
            confirmAction: handleCpUploadStatusUpdate,
            confirmActionParams: cpId,
            closeModal: closeModel,
            dialogAttr: {
          	  fullWidth:true,
          	  maxWidth: "sm"
            }
  	  },"confirm"));
    }
    
    const handleCpUploadStatusUpdate = (newCpStatus, currentCpId, cptestDescription, cpStatusMessage) => {
        dispatch(hideAlert())
        let cpData = {
            cpUploadedTestStatus: newCpStatus,
            cpId: currentCpId
        }
        console.log(cpData)
        dispatch(approveOrRejectCp(cpData)).then(res => {

            if (res === "Success") {
                dispatch(showAlert("Content Package " + cptestDescription + " is " + cpStatusMessage, "success"))
                setCpStatus(newCpStatus);
                handleGetTestItems()
            } else {
                dispatch(showAlert("Content Package " + cptestDescription + " " + cpStatusMessage + " process failed", "error"))
            }
        });
        closeModel();
    }

    const handleTestOnClick = (userData, dataUserId, isSampleTest, ptTestMode,cpId) => {

        console.log(userData);
        console.log(dataUserId);
        console.log(props.urlData);
        let tdDomainUrl = props.urlData.tddomainUrl;
        let path;
        let pendingtsData;
        path = 'welcome';

        var jsonData = {
            userId: dataUserId,
            bpId: userData.branchingProfileId,
            bpName: userData.branchingProfileName,
            administerTest: true,
            isDemoSite: false,
            isKioskApp: false,
            kioskData: {},
            path: path,
            ethnicity: "Asian, Asian American or Pacific Islander",
            gender: "Male",
            firstName: userData.firstName,
            lastName: userData.lastName,
            studentId: dataUserId,
            studentIdPk: userData.studentIdPK,
            isPracticeTest: true,
            practiceTest: true,
            isSamplePtTest: isSampleTest,
            ptTestMode: ptTestMode,
            pendingtsData: pendingtsData,
            categoryId : userData.categoryId,
            testAlias : userData.parentTestAlias,
            cpId :cpId
        };

        dispatch(startTest(jsonData)).then((res) => {
            console.log(res);
            let tdURL;
            if (tdDomainUrl && tdDomainUrl !== null && tdDomainUrl !== '') {
                tdURL = tdDomainUrl + "#/?d=" + res;
            } else {
                tdURL = "http://test.local-accuplacer.com:9001/#/?d=" + res;
            }

            let screen = window.screen;
            let wwidth = screen.width, wheight = screen.height, LeftPosition = 0, TopPosition = 0;
            var varWin = window.open(tdURL, 'atWindow', 'menubar=no,height=' + wheight + ', width=' + wwidth + ',top=' + TopPosition + ',left=' + LeftPosition +
                ',scrollbars=yes, resizable=yes, toolbar=no, menubar=no, status=no,location=no');
            varWin.moveTo(0, 0)
            varWin.resizeTo(screen.width, screen.height);
            varWin.focus();
        })


    }

    const showRejectionMessage = (rejectionMsg) => {
        dispatch(
            showModal(
              {
                title: "Rejection Message",
                message: rejectionMsg,
                dialogAttr: {
                    fullWidth:true,
                    maxWidth: "sm"
                }
              },
              "alert"
            )
        );
    };


    return (
        <div className={`${classes.root} view-practice-test-page`}>
            <Grid item xs={12} className='titleBar' align='center'>
                <Typography variant='h5' component='h2' color='primary'>
                    {t("viewPracticeTestPageTitle")}
                </Typography>
            </Grid>
            <div className="row">
                <div className="col-sm-12" >
                    <Card className={classes.card}>
                        <CardHeader
                            title={cpData && cpData.cpTestCategory && cpData.cpTestCategory.categoryName ? (<label className={classes.labelFont} >{cpData.cpTestCategory.categoryName}</label>) : null}
                            className="section-header"
                        />
                        <CardActions disableSpacing>
                            <ACL functionId="12">
                                {cpStatus  && cpStatus >3 ? (
                                    <Grid
                                        container
                                        direction="row"
                                        justify="flex-end"
                                        alignItems="center"
                                    >
                                { cpStatus < 6  ? (
                                        <RejectionDialog classes={classes} cpData={cpData} showButtons={setCpStatus} />
                                        ) : null}
                                          &nbsp;&nbsp;
                                        { cpStatus === 4 ? (
                                            <Tooltip title="Approve" aria-label="Approve">
                                            <Button
                                                variant="contained"
                                                color="inherit"
                                                className={classes.button}
                                                startIcon={<Check color="primary" />}
                                                onClick={() => performApproveCP(5, cpData.id, cpData.testDescription, "Approved")}
                                            >
                                                Approve
                                            </Button>

                                        </Tooltip> ) : null}
                                    </Grid>):null}
                            </ACL>
                        </CardActions>
                        <CardContent className={classes.labelFont}>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label >Practice Test Name</label>
                                </div>
                                <div className="col-sm-6">
                                    <label >{cpData.testName}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label >Test Description</label>
                                </div>
                                <div className="col-sm-8">
                                    <label >{cpData.testDescription}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label >Test Alias</label>
                                </div>
                                <div className="col-sm-8">
                                    <label >{cpData.testAlias}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label >Total Number of Items</label>
                                </div>
                                <div className="col-sm-8">
                                    <label>{itemListCount}</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <label >Length of Practice test</label>
                                </div>
                                <div className="col-sm-8">
                                    <label >{testDetail.operationalTestLength} </label>
                                </div>
                            </div>
                                {cpStatus && cpStatus >3 && cpStatus !==6 ?(
                                    <div className="row">
                                    <div className="col-sm-3">
                                        <label >Preview Test</label>
                                    </div>
                                    <div className="col-sm-6">
                                        <Button variant="outlined" color="primary" size="small" onClick={(event) => handleTestOnClick(itemsData, userId, false, 1,cpId)}>Learn As You Go</Button> &nbsp;
                                        <Button variant="outlined" color="primary" size="small" onClick={(event) => handleTestOnClick(itemsData, userId, true, 2,cpId)}>Practice Test</Button>
                                    </div>
                                </div>):null
                                }
                            <div className="row">
                                <div className="col-sm-12">
                                    <Paper className={classes.root}>
                                        <MaterialTable
                                            title="Process History"
                                            columns={[
                                                {
                                                    title: 'User', field: 'id',
                                                    render: rowData => <span>{rowData.firstName} {rowData.lastName}</span>,
                                                },
                                                {
                                                    title: 'Process Type', field: 'statusDescription',
                                                    render: rowData => (6 === rowData.cpStatus) ? 
                                                        <Link component="button" aria-label={rowData.statusDescription}
                                                            onClick={()=>showRejectionMessage(rowData.cpRejectDesc)}>
                                                            {rowData.statusDescription}
                                                        </Link> : rowData.statusDescription
                                                },
                                                {
                                                    title: 'Date',
                                                    field: 'createdOn',
                                                    render: rowData => rowData.createdOn
                                                }
                                            ]}
                                            localization={{
                                                pagination : {
                                                  firstAriaLabel: 'First Page',
                                                  previousAriaLabel: 'Previous Page',
                                                  lastAriaLabel: 'Last Page',
                                                  nextAriaLabel: 'Next Page'
                                                }
                                            }}
                                            data={cpAudit.data}
                                            options={{
                                                headerStyle: {
                                                    fontWeight: 'bold'
                                                },
                                                search: false,
                                                sorting: false,
                                                grouping: false,
                                                draggable: false,
                                                pageSize: 5,
                                                pageSizeOptions: [5, 10, 25, 50],
                                                emptyRowsWhenPaging: false

                                            }}
                                        />
                                    </Paper>

                                </div>
                            </div>


                        </CardContent>

                    </Card>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12" >
                    <Card className={classes.card}>
                        <CardHeader
                            title={(processLog && processLog.logItems && processLog.logItems.length > 0) ? "Process Log" : "View Items"}
                            className="section-header"
                        />
                        <CardContent>

                            <div>
                                {processLog && processLog.logItems && processLog.logItems.length === 0 && (


                                    <Paper className={classes.root}>
                                        <MaterialTable
                                            title=""
                                            tableRef={dataTableRef}
                                            data={query =>
                                                new Promise((resolve, reject) => {
                                                    dispatch(getCPItems({ query, cpId: props.match.params.id })).then(result => {
                                                        setTotalRowCount(result.totalCount);
                                                        resolve({
                                                            data: result.data,
                                                            page: result.page,
                                                            totalCount: result.totalCount,
                                                        })

                                                    })
                                                })

                                            }
                                            options={{
                                                actionsColumnIndex: 3,
                                                headerStyle: {
                                                    fontWeight: 'bold'
                                                },
                                                actionsCellStyle: {
                                                    width: 100,
                                                    paddingLeft: 20
                                                },
                                                searchFieldStyle: { searchPlaceholder: 'Search by UIN' },
                                                search: true,
                                                sorting: true,
                                                pageSize: 10,
                                                grouping: false,
                                                draggable: false,
                                                pageSizeOptions: [10, 25, 50, 100, 250],
                                                emptyRowsWhenPaging: false
                                            }}
                                            localization={{
                                                header: {
                                                    actions: 'Action'
                                                },
                                                toolbar: {
                                                    searchTooltip: 'Search by Item UIN',
                                                    searchPlaceholder: 'Search by Item UIN'
                                                },
                                                pagination : {
                                                    firstAriaLabel: 'First Page',
                                                    previousAriaLabel: 'Previous Page',
                                                    lastAriaLabel: 'Last Page',
                                                    nextAriaLabel: 'Next Page'
                                                }
                                            }}
                                            columns={[
                                                {
                                                    title: 'Item UIN', field: 'uin',
                                                    render: rowData => rowData.uin,
                                                },
                                                {
                                                    title: 'Test Domain', field: 'strandName',
                                                    render: rowData => rowData.strandName,
                                                },
                                                {
                                                    title: 'Calculator',
                                                    field: 'calculator',
                                                    render: rowData => <span>{rowData.calculator === 1 ? "Basic" : rowData.calculator === 2 ? "Square Root" : rowData.calculator === 3 ? "Graphing" : "Off"}</span>
                                                }
                                            ]}
                                            components={{
                                                Action: props => (
                                                    <Tooltip title="Preview" aria-label="view">
                                                        <Fab
                                                            className={classes.viewBtn}
                                                            size="small"
                                                            onClick={() => handlePracticeItemPreview(props.data.itemId, props.data.testDetailId, props.data.strandDetailId, cpData.testName)}
                                                        >
                                                            <PageView />
                                                        </Fab>
                                                    </Tooltip>
                                                ),

                                            }}
                                            actions={[
                                                {
                                                    icon: 'save',
                                                    tooltip: 'Preview',
                                                    onClick: (event, row) => {
                                                        // Do save operation
                                                        handlePracticeItemPreview(row.itemId, row.testDetailId, row.strandDetailId, cpData.testName)
                                                    }
                                                }
                                            ]}
                                        />
                                    </Paper>
                                )}
                            </div>
                            {processLog && processLog.logItems && processLog.logItems.length > 0 &&
                                <div className="row">
                                    <div className="col-sm-12">
                                        <Paper className={classes.root}>
                                            <MaterialTable
                                                title=""
                                                columns={[
                                                    { title: 'Key', field: 'key' },
                                                    { title: 'Message', field: 'message' }
                                                ]}
                                                data={processLog.logItems}
                                                options={{
                                                    headerStyle: {
                                                        fontWeight: 'bold'
                                                    },
                                                    search: true,
                                                    sorting: true,
                                                    pageSize: 10,
                                                    pageSizeOptions: [10, 25, 50, 100, 250],
                                                    emptyRowsWhenPaging: false,
                                                    rowStyle: rowData => ({
                                                        backgroundColor: (rowData && rowData.status === "SUCCESS") ? '#dff3df' : '#f9cdcd'
                                                    })
                                                }}
                                            />
                                        </Paper>
                                    </div>
                                </div>
                            }
                        </CardContent>
                    </Card>
                </div>
            </div>


        </div>
    )
}

const mapStateToProps = state => ({
    urlData: state.login.data
});

export default connect(mapStateToProps)(ViewPracticeTest)