import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import {
  getCurrentPAASession,
  resetCurrentPAASession,
} from "../../redux/preAssessmentActions";
import { connect } from "react-redux";
import { hideAlert } from "../../redux/alertActions";
import { showProgress, hideProgress } from "../../redux/progressActions";
import { showModal, hideModal } from "../../redux/modalAction";
import Badge from "@material-ui/core/Badge";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  margin: {
    margin: theme.spacing(1),
  },
  paper: {
    padding: theme.spacing(2),
    boxShadow: "None",
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  ulStyle: {
    justifyContent: "center",
    listStyle:"none",
  },
  button: {
    maxWidth: 300,
    margin: theme.spacing(1),
  },
}));

const PAATest = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [horizontal] = React.useState("left");
  const [vertical] = React.useState("top");
  const showResume = props.paa && props.paa.paaId;

  // Code for resume PAA
  useEffect(() => {
    dispatch(hideAlert());

    dispatch(showProgress());
    dispatch(getCurrentPAASession()).then((res) => {
      dispatch(hideProgress());
    });
  }, []);

  const dialogTitle = (
    <React.Fragment>A PAA session is already in-progress!</React.Fragment>
  );
  const dialogDesc = (
    <React.Fragment>
      Are you sure you want to continue? If yes, the in-progress PAA session
      will be closed and a new PAA session will begin.
    </React.Fragment>
  );

  const dialogOnSubmit = () => {
    // starting the new PAA session.
    dispatch(resetCurrentPAASession());
    props.history.push("/home/test/pre-assessment-wizard");
    dispatch(hideModal());
  };

  const dialogOnClose = (event, reason) => {
    //TODO- put focus on resume button
    if ("backdropClick" !== reason) dispatch(hideModal());
  };

  const dialogAttr = {
    //disableBackdropClick: true,
    disableEscapeKeyDown: true,
  };

  const openConfirmModal = (...callBackParams) => {
    if (showResume) {
      dispatch(
        showModal(
          {
            title: dialogTitle,
            message: dialogDesc,
            confirmAction: dialogOnSubmit,
            confirmActionParams: callBackParams,
            closeModal: dialogOnClose,
            dialogAttr: dialogAttr,
          },
          "confirm"
        )
      );
    } else {
      props.history.push("/home/test/pre-assessment-wizard");
    }
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="baseline"
    >
      <Grid item xs={12} className="titleBar" align="center">
        <Typography variant="h5" component="h1" color="primary">
          Texas Success Initiative Pre-Assessments 2.0
        </Typography>
      </Grid>
      <Grid item className="col-xs-12 col-sm-8 cb-item-list cb-item-list-rich">
        <ul className={classes.ulStyle}>
          <li className="cb-type-article noTitleBar" key="preassessment">
          <div className="cb-item-container">
            <div className="cb-icon">
              <span
                className="cb-icon cb-icon-circular cb-compose cb-purple1-color"
                aria-hidden="true"
              ></span>
            </div>

            <div className="cb-item-text">
              <h2 className="cb-item-title testHeader" >
                Texas Success Initiative Pre-Assessments 2.0
              </h2>
              <p className="cb-item-des" style={{margin: '0',fontSize: '.875rem',lineHeight: '1.71428571em',color: '#505050'}} >
              The Texas Success Initiative Assessment, 2.0 (TSIA2) is the test created by the state of Texas to help<br/>
              determine whether students are ready for freshman-level college courses in Mathematics and English<br/>
              Language Arts and Reading (ELAR).
              </p>
              <br/>
              <p className="cb-item-des">
              TSI Rule 4.55(b) requires that all testing sites provide a Pre-Assessment Activity (PAA) for all test takers<br/>
              prior to test administration. The PAA will help you understand the following: 
              </p>
                <ol className="cb-item-des">
                  <li>&emsp;Why you are being tested.</li>
                  <li>&emsp;How the TSIA2 works, including calculator and highlighter tutorials and practice test questions.</li>
                  <li>&emsp;How test results impact your postsecondary options.</li>
                  <li>&emsp;Resources available to you.</li>
                </ol>
            </div>
            </div>
            <div className="cb-item-meta">
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                {showResume ? (
                  <Badge
                    color="secondary"
                    badgeContent={"In Progress"}
                    anchorOrigin={{
                      horizontal,
                      vertical,
                    }}
                    overlap="circular"
                    className={classes.margin}
                  >
                    <Link to={"/home/test/pre-assessment-wizard"}>
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.button}
                        aria-label="Begin Pre-Assessment Activity"
                      >
                        Resume Pre-Assessment Activity
                      </Button>
                    </Link>
                  </Badge>
                ) : (
                  ""
                )}
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  onClick={openConfirmModal}
                  aria-label="Begin Pre-Assessment Activity"
                >
                  Begin Pre-Assessment Activity
                </Button>
              </Grid>
            </div>
          </li>
        </ul>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
    paa: state.paa,
});
export default connect(mapStateToProps)(PAATest);
