import React from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "@material-ui/core";
import ReactPlayer from "react-player";
import Container from "@material-ui/core/Container";
import { useState, useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import ActionTypes from "../../constants/ActionTypes";
import { showProgress, hideProgress } from "../../redux/progressActions";
import { hideAlert } from "../../redux/alertActions";
import { getStep2VideoUrlList } from "../../redux/preAssessmentActions";

const PAAStep2 = (props) => {
  const dispatch = useDispatch();
  const [videoFlag, setVideoFlag] = useState([]);
  const [urlList,setUrlList] = useState([]);
  const handleVideoStatus = (i) => {
    let index = i;
    if(!videoFlag.includes(i)){
      setVideoFlag([...videoFlag,index]);
    }
  };

  useEffect(() => {
    dispatch(hideAlert());
    dispatch(showProgress());

    if(urlList.length === 0){
      dispatch(getStep2VideoUrlList()).then((res) => {
        setUrlList(...urlList,res.url);
        dispatch(hideProgress());
      });
    }
    if ((urlList.length!=0 && videoFlag.length === urlList.length)) {
      dispatch({
        type: ActionTypes.UPDATE_PAA,
        data: { ...props.paa, videosWatched: true },
      });
    }
    return () => {};
  }, [videoFlag]);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container maxWidth="md">
      {urlList.length > 0
        ? urlList.map((object, i) => (
            <Grid
              item
              xs={12}
              style={{ display: "flex",justifyContent: "center" ,marginBottom: '5%'}}
            >
              <ReactPlayer
                url={object}
                onEnded={()=> handleVideoStatus(i)}
                width='857px'
                height='483px'
                controls={true}
                key={{i}}
              />
            </Grid>
          ))
        : ""}
      <Grid container justifyContent="flex-end">
        <div className="cb-type-article noTitleBar" key="preassessment">
          <div className="cb-item-text">
            <h2 className="cb-item-title step4Title">Who needs to take the TSIA2?</h2>
            {/* <p className='cb-item-des'><strong>{row.testCategoryName}</strong></p> */}
            <p className="cb-item-des">
              Not all students entering higher education need to take the TSIA2.
              There are many ways a student can be <strong>exempt</strong>,
              meaning they do not need to take the TSIA2 and can enroll in any
              freshman-level college course without required support.
            </p>
            <p className="cb-item-des">
              TSI exemptions are subject-specific, which means a student may
              qualify for an exemption in one area (such as ELAR) but not in
              another area (such as Mathematics). If you meet any of the
              criteria below, you may be exempt from one or both areas of the
              TSIA2. Contact your college adviser to confirm. Undergraduate
              students who enter without an exemption are required to take the
              Texas Success Initiative Assessment 2.0 (TSIA2).
            </p>
            <ul className="cb-item-list">
              <li>
                ACT&reg;: combined score of 23 with a minimum of 19 on English
                and Mathematics within the past 5 years ~OR~
              </li>
              <li>
                SAT&reg; (Administered prior to March 2016): combined score of
                1070 with a minimum of 500 on Critical Reading and Mathematics
                within the past 5 years ~OR~
              </li>
              <li>
                SAT&reg; (Administered on or after March 5, 2016): minimum of
                530 on Mathematics for exemption in TSI Math; minimum of 480 on
                Evidenced-Based Reading &amp; Writing (EBRW) for exemption in
                TSI Reading and Writing ~OR~
              </li>
              <li>
                Eleventh grade exit-level TAKS minimum scale score of 2200 on
                Math, 2200 on English Language Arts, and a Writing subsection
                score of at least 3 within the past 5 years ~OR~
              </li>
              <li>
                STAAR&reg; minimum score of 4000 on English III-Level 2 end of
                course (EOC) and a minimum score of 4000 on Algebra II-Level 2
                EOC ~OR~
              </li>
              <li>
                You have satisfactorily completed college-level coursework as
                determined by the receiving institution. ~OR~
              </li>
              <li>
                You have earned an associate or baccalaureate degree from a
                college. ~OR~
              </li>
              <li>
                You are enrolled in a certificate program of one year or less at
                a public college or public technical institute. ~OR~
              </li>
              <li>
                You are serving on active duty as a member of the armed forces
                of the United States, the Texas National Guard, or as a member
                of a reserve component of the armed forces of the United States
                and have been serving for at least three years preceding
                enrollment. ~OR~
              </li>
              <li>
                On or after August 1, 1990, you were honorably discharged,
                retired, or released from active duty as a member of the armed
                forces of the United States or the Texas National Guard or
                service as a member of a reserve component of the armed forces
                of the United States. ~OR~
              </li>
              <li>
                You completed a College Preparatory Course (CPC) in high school
                and are enrolling within 24 months from the date of high school
                graduation (see your adviser for additional requirements
                regarding this exemption). ~OR~
              </li>
              <li>
                You have received a temporary ESOL Waiver granted by the college
                after demonstrating limited English proficiency.
              </li>
            </ul>
          </div>
          <div className="cb-item-meta">
            <Grid container direction="row" justifyContent="flex-end"></Grid>
          </div>
        </div>
        <div style={{marginTop:'35px'}} className="cb-type-article noTitleBar" key="preassessment1">
          <div className="cb-item-text">
            <h2 className="cb-item-title step4Title">Why does the TSIA2 matter?</h2>
            {/* <p className='cb-item-des'><strong>{row.testCategoryName}</strong></p> */}
            <p className="cb-item-des">
              Accurate placement is the first step towards successful college
              completion. The results of TSIA2, in conjunction with your
              academic background, goals, and interests, are used by academic
              advisers and counselors to place you in the appropriate courses
              that match your achievement level.
            </p>
          </div>
          <div className="cb-item-meta">
            <Grid container direction="row" justifyContent="flex-end"></Grid>
          </div>
        </div>
        <div className="cb-type-article noTitleBar" key="preassessment2">
          <div className="cb-item-text">
            <h2 className="cb-item-title step4Title">
              How is the test designed, what&rsquo;s covered and how long will
              it take?
            </h2>
            {/* <p className='cb-item-des'><strong>{row.testCategoryName}</strong></p> */}
            <p className="cb-item-des">
              The TSIA2 is a computer adaptive test, which means questions may
              increase or decrease in difficulty depending on the
              student&rsquo;s responses. The TSIA2 is not timed, and you may
              pause the test anytime during the session except during the essay
              portion. If you stop and save your work to finish later, the test
              must be completed within 13 calendar days. On average, it takes
              approximately 45-60 minutes per section depending on how prepared
              you are. In most cases, you will be provided with a score
              immediately upon completion, including for the essay portion.
            </p>
            <p className="cb-item-des">
              The TSIA2 has two subject areas, English Language Arts and Reading
              (ELAR) and Mathematics. You may be required to take one or both
              sections. When you take the TSIA2, you will start with the College
              Readiness Classification (CRC) Test. Depending on your CRC score,
              you may be given a diagnostic test with additional questions. If
              you are given the diagnostic test, be sure to stay motivated and
              do your best because you can still demonstrate college
              readiness.&nbsp;
            </p>
            <p className="cb-item-des">
              <strong>Resource:</strong>&nbsp;For more details regarding the
              contents of the TSIA2, you may also explore the&nbsp;
              <Link
              className="step5Link"
                href="https://accuplacer.collegeboard.org/accuplacer/pdf/tsia2-student-informational-brochure.pdf"
                target="_blank"
              >
                TSIA 2.0 Student Informational Brochure.
              </Link>
            </p>
          </div>
        </div>
      </Grid>
    </Container>
  );
};
const mapStateToProps = (state) => ({
  paa: state.paa,
});
export default connect(mapStateToProps)(PAAStep2);
